@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
    --main-font: 'Montserrat', sans-serif;
    --second-font: 'Inter', sans-serif;
}

html {
    font-size: 16px;
}

body {
    margin: 0;
    font-family: var(--main-font), BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*.small-pills button{*/
/*  padding: .2rem .5rem !important;*/
/*}*/
.select-label {
    color: rgb(107 114 128);
}

.small-pills .accordion-title {
    padding: 1rem !important;
}

.accordion-title {
    color: #000
}

.accordion-title h2 {
    width: 100%;
}

.schedule-btn {
    height: 42px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.disabled {
    opacity: .7;
    pointer-events: none;
    cursor: not-allowed;
}

[data-testid="modal-overlay"] {
    background: rgba(255, 255, 255, .1) !important;
}

[data-testid="flowbite-accordion"] > button {
    padding: 0 !important;
}

[data-testid="flowbite-accordion-arrow"] {
    display: none;
}

.developer-data-block {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(150, 150, 150, 100);
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 10;
}
